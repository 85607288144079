export default {
	props: [
	],
	data () {
		return {
		};
	},
	mounted() {
	},
	computed: {
	},
	methods: {
    }
}