import homePage from './_pages/home-page/home-page.vue'
import projectsLoggerPage from './_pages/projects-logger-page/projects-logger-page.vue'
import myProcessPage from './_pages/my-process-page/process-page.vue'

// import resourcesPage from './_pages/resources-listing-page/resources-listing-page.vue'
// import workPage2 from './_pages/work/work.vue'
// import workPage from './components/projects-listing-timeline-iframe/projects-listing-timeline.vue'
// import processPage from './_pages/process-page/process-page.vue'

export default [
	{
		path: '/',
		name: 'home',
		component: homePage,
	},
	{
		path: '/work',
		name: 'work',
		component: projectsLoggerPage,
	},
	{
		path: '/process',
		name: 'process',
		component: myProcessPage,
	},
	// {
	// 	path: '/pet',
	// 	name: 'pet',
	// 	component: resourcesPage,
	// },
];