

export default {
	components: {
	},
	props: [
    'url1',
    'url2',
	],
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
  },
}