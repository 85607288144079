import Rcsed from "../../projects/rcsed/rcsed.vue";
// import ProjectsListingSidebar from "../../components/projects-listing-sidebar/projects-listing-sidebar.vue";
import ProjectListingSidebar from "../../components/project-listing-sidebar/project-listing-sidebar.vue";
import Hoxton from "../../projects/hoxton/hoxton.vue";
import Espc from "../../projects/espc/espc.vue";
import Baa from "../../projects/baa/baa.vue";
import Doso from "../../projects/doso/doso.vue";
import Bsq from "../../projects/bsq/bsq.vue";
import Irt from "../../projects/irt/irt.vue";
import Lj from "../../projects/lj/lj.vue";
import Who from "../../projects/who/who.vue";
import Argus from "../../projects/argus/argus.vue";
import Clemmie from "../../projects/clemmie/clemmie.vue";
import Webdesign from "../../projects/webdesign/webdesign.vue";
import NhsStats from "../../projects/nhs-reg-stats/nhs-reg-stats.vue";
import Furni from "../../projects/furni/furni.vue";
import MiResearch from "../../projects/miresearch/miresearch.vue";
import Sibcas from "../../projects/sibcas/sibcas.vue";
import Alchemist from "../../projects/alchemist/alchemist.vue";
import MenziesSmart from "../../projects/menzies-smart/menzies-smart.vue";
import NhsBereavement from "../../projects/nhs-bereavement/nhs-bereavement.vue";
import Breathe from "../../projects/breathe/breathe.vue";
import VideoBackgrounds from "../../projects/video-backgrounds/video-backgrounds.vue";

export default {
  components: {
    'rcsed': Rcsed,
    'projectListingSidebar': ProjectListingSidebar,
    'espc': Espc,
    'hoxton': Hoxton,
    'baa': Baa,
    'doso': Doso,
    'bsq': Bsq,
    'irt': Irt,
    'lj': Lj,
    'who': Who,
    'argus': Argus,
    'clemmie': Clemmie,
    'webdesign': Webdesign,
    'nhsStats': NhsStats,
    'furni': Furni,
    'miResearch': MiResearch,
    'sibcas': Sibcas,
    'alchemist': Alchemist,
    'menziesSmart': MenziesSmart,
    'nhsBereavement': NhsBereavement,
    'breathe': Breathe,
    'videoBackgrounds': VideoBackgrounds,
  },

  data () {
    return {
      // ...mainDataStore,

      selectedproject:0,
      incognitoMode:true,

      projects: [
        {
          name: "Breathe",
          incogintoName: "Cardiac coherence app",
          date: "Aug 2021",
          bgColor: "#010109",
          previewImage: "https://palmaka.design/folio-db/projects/breathe/thumb@2x.jpg",
          platform: "Cross-platform (Flutter)",
        },
        {
          name: "Royal College of Surgeons of Edinburgh",
          incogintoName: "Association of surgeons",
          date: "May 2020",
          bgColor: "#29a3ae",
          previewImage: "https://palmaka.design/folio-db/projects/rcsed/thumb@2x.jpg",
          platform: "Cross-platform (Flutter)",
        },
        {
          name: "Sibcas",
          incogintoName: "Portacabin managment app",
          date: "Apr 2019",
          bgColor: "#ff8f00",
          previewImage: "https://palmaka.design/folio-db/projects/sibcas/thumb@2x.jpg",
          platform: "Android",
        },
        {
          name: "Alchemist Conjurer",
          incogintoName: "Cocktail-bar app loyalty scheeme",
          date: "Dec 2018",
          bgColor: "#000000",
          previewImage: "https://palmaka.design/folio-db/projects/alchemist/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "Video Hero Backgrounds",
          incogintoName: "Video Hero Backgrounds",
          date: "Dec 2018",
          bgColor: "#111111",
          previewImage: "https://palmaka.design/folio-db/projects/video-backgrounds/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "Menzies Aviation",
          incogintoName: "Aviation auditing app",
          date: "Aug 2018",
          bgColor: "#0090de",
          previewImage: "https://palmaka.design/folio-db/projects/menzies-smart/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "Argus Dashboard",
          incogintoName: "Transaction monitoring app",
          date: "Apr 2018",
          bgColor: "#50d1c2",
          previewImage: "https://palmaka.design/folio-db/projects/argus/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "MI Research",
          incogintoName: "Medical research managment",
          date: "Nov 2017",
          bgColor: "#17afd4",
          previewImage: "https://palmaka.design/folio-db/projects/miresearch/thumb@2x.jpg",
          platform: "Web",
        },
        {
          name: "DoSo",
          incogintoName: "Automated saving & investing app",
          date: "May 2017",
          bgColor: "#45bc62",
          previewImage: "https://palmaka.design/folio-db/projects/doso/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "IRT",
          incogintoName: "Housing energy rating managment",
          date: "Apr 2017",
          bgColor: "#4286f5",
          previewImage: "https://palmaka.design/folio-db/projects/irt/thumb@2x.jpg",
          platform: "Web",
        },
        {
          name: "ESPC",
          incogintoName: "Property market app",
          date: "May 2017",
          bgColor: "#0293e8",
          previewImage: "https://palmaka.design/folio-db/projects/espc/thumb@2x.jpg",
          platform: "iOS / Android",
        },
        {
          name: "Hoxton",
          incogintoName: "Innovative hotel app",
          date: "Mar 2017",
          bgColor: "#405765",
          previewImage: "https://palmaka.design/folio-db/projects/hoxton/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "NHS Bereavement",
          incogintoName: "Respectfully informing bereaved families",
          date: "Mar 2017",
          bgColor: "#20222e",
          previewImage: "https://palmaka.design/folio-db/projects/nhs-bereavement/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "Be Avalanche Aware", 
          incogintoName: "Avalanche awarness and reporting app",
          date: "Feb 2017",
          bgColor: "#0d3a6f",
          previewImage: "https://palmaka.design/folio-db/projects/baa/thumb@2x.jpg",
          platform: "iOS / Android",
        },
        {
          name: "NHS Regional Stats",
          incogintoName: "health data by region",
          date: "Dec 2017",
          bgColor: "#120c13",
          previewImage: "https://palmaka.design/folio-db/projects/nhs-reg-stats/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "Furni",
          incogintoName: "Innovative furniture shopping",
          date: "Nov 2016",
          bgColor: "#d25b2f",
          previewImage: "https://palmaka.design/folio-db/projects/furni/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "WHO European health statistics",
          incogintoName: "The international report from the collaborative study",
          date: "Aug 2016",
          bgColor: "#163a75",
          previewImage: "https://palmaka.design/folio-db/projects/who/thumb@2x.jpg",
          platform: "iOS / Android",
        },
        {
          name: "Clemmie",
          incogintoName: "Cloud-hosting stats",
          date: "Jun 2016",
          bgColor: "#171516",
          previewImage: "https://palmaka.design/folio-db/projects/clemmie/thumb@2x.jpg",
          platform: "iOS / Android",
        },
        {
          name: "Ballachulish Quarry Stories",
          incogintoName: "Local attraction guide",
          date: "Mar 2016",
          bgColor: "#141414",
          previewImage: "https://palmaka.design/folio-db/projects/bsq/thumb@2x.jpg",
          platform: "Cross-platform (Flutter)",
        },
        {
          name: "Learning Journals",
          incogintoName: "Observations of children\"s significant learning",
          date: "Feb 2016",
          bgColor: "#66b1d1",
          previewImage: "https://palmaka.design/folio-db/projects/lj/thumb@2x.jpg",
          platform: "iOS",
        },
        {
          name: "Brochure Webdesign",
          incogintoName: "Observations of children\"s significant learning",
          date: "2014 - 2016",
          bgColor: "#0a0e17",
          previewImage: "https://palmaka.design/folio-db/projects/webdesign/thumb@2x.jpg",
          platform: "Web",
        },
      ],
    };
  },
  
  computed: {  
  },

  watch: {
  },

  mounted() {
    
  },
  
  methods: {

  },
}