
import Flickity from 'vue-flickity';
import flickityAsNavFor from 'flickity-as-nav-for';

import KenBurnsSlideshow from '../ken-burns-slideshow/ken-burns-slideshow.vue';

export default {
	components: {
		Flickity,
    'kenBurnsSlideshow': KenBurnsSlideshow,
	},
	props: {
		projects: {
			type: Array,
			required: false,
		},
    impressionMode: {
      type: String,
      required: false,
    }
	},

  data () {
    return {
      selectedProject: 0,

      // ______________________
      // Set Flickity carousels

      infoSlides: {
        asNavFor: '.image-slides',
        initialIndex: 0,
        draggable: false,
        prevNextButtons: false, 
        pageDots: false,
        fade: false,
        adaptiveHeight: false,
        // selectedAttraction: 0.022,
        // friction: 0.28,
        arrowShape: { 
          x0: 25,
          x1: 55, y1: 30,
          x2: 60, y2: 25,
          x3: 35
        },
      },

      imageSlides: {
        draggable: true,
        prevNextButtons: false, 
        pageDots: false,
        fade: false,
        // selectedAttraction: 0.025,
        // friction: 0.28,
      },

      actionSlides: {
        asNavFor: '.image-slides',
        draggable: false,
        prevNextButtons: false,
        adaptiveHeight: true, 
        pageDots: false,
        // selectedAttraction: 0.028,
        // friction: 0.28,
      },   
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    nextInfoSlide() {
      this.$refs.infoSlides.next();
    },
  },
}