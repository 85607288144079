import ComplexProjectViewer from '../../components/complex-project-viewer/complex-project-viewer.vue';
export default {
	components: {
		'complexProjectViewer': ComplexProjectViewer,
	},
  props: [
    'incognitoMode',
  ],
  data () {
    return {
      name: 'Be Avalanche Aware',
      incognitoName: 'Avalanche awarness and reporting app',
      bgColor: '#FF5722',//12306c 0d3a6f
      platforms: [
        {
          deviceName: 'mockups',
          tabName: 'Overview',
          screens: [
            { image: 'https://palmaka.design/folio-db/projects/baa/mockups/1@2x.jpg'},
          ],
        },
        {
          deviceName: 'iPhone7',
          tabName: 'iPhone screens',
          screens: [
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-1@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-2@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-3@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-4@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-5@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-6@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-7@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-8@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-9@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-10@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-11@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-12@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-13@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-14@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-15@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-16@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-17@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-18@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-19@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-20@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-21@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-22@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-23@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-24@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-25@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-26@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-27@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-28@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-29@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-30@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-31@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-32@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-33@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-34@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-35@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-36@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-37@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-38@2x.png'},
            { image: 'https://palmaka.design/folio-db/projects/baa/ios-iphone/img-39@2x.jpg'},
          ],
        },
        {
          deviceName: 'googlePixel',
          tabName: 'Android screens',
          screens: [
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-1@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-2@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-3@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-4@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-5@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-6@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-7@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-8@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-9@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-10@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-11@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-12@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-13@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-14@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-15@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-16@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-17@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-18@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/baa/android-phone/img-19@2x.jpg'},
          ],
        },
      ],
    };
  },
}