import Flickity from 'vue-flickity';
import { mainDataStore } from '../../store/data.js';
import flickityAsNavFor from 'flickity-as-nav-for';

import ProjectsListingSlider from '../../components/projects-listing-slider/projects-listing-slider.vue';
// import GravitationalField from '../../components/gravitational-field/gravitational-field.vue';

// Vue.use(flickityAsNavFor)

export default {
  components: {
    Flickity,
    flickityAsNavFor,
    'projectsListingSlider': ProjectsListingSlider,
    // 'gravitationalField': GravitationalField,
  },

  data () {
    return {

      selectedSubSection: 1,
      // ...mainDataStore,
      // ...this.$store.getters.baseValues,
      // ...this.$store.getters.verticalRhythmValues,

      // impressionMode: 'personal',

      // projects: [
      //   {
      //     name: 'Swag Spammer',
      //     description: 'Your creativity will quickly take over while using this midi sequencer. Swag Spammer is designed let you start sequencing your hardware in seconds. It favors intuition over theory and it’s perfect for experimental approach, offering enough precision to refine the loop to match your vision.',
      //     detailsLink: '#',
      //     bgColor: '#BBDEE4',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'src/_pages/resources-listing-page/img/temp-1@2x.png',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'download mac win',
      //         link: '#'
      //       },
      //       {
      //         name: 'Github',
      //         class: 'go-to github',
      //         link: '#'
      //       },
      //     ],
      //   },
      //   {
      //     name: 'UITS Sketch UI Kit',
      //     description: 'Your creativity will quickly take over while using this midi sequencer. Swag Spammer is designed let you start sequencing your hardware in seconds. It favors intuition over theory and it’s perfect for experimental approach, offering enough precision to refine the loop to match your vision.',
      //     detailsLink: '#',
      //     bgColor: '#BBDEE4',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'src/_pages/resources-listing-page/img/temp-1@2x.png',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'download mac win',
      //         link: '#'
      //       },
      //       {
      //         name: 'Github',
      //         class: 'go-to github',
      //         link: '#'
      //       },
      //     ],
      //   },
      //   {
      //     name: 'UI Color System',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: 'wheat',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'download sketch',
      //         link: '#'
      //       },
      //       {
      //         name: 'Github',
      //         class: 'go-to github',
      //         link: '#'
      //       },
      //     ],
      //   },
      //   {
      //     name: 'OSX Resize',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: '#fEDEE8',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'download mac',
      //         link: '#'
      //       },
      //       {
      //         name: 'Github',
      //         class: 'go-to github',
      //         link: '#'
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Animated Abstract Backgrounds',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: '#fEDEE8',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'download mac',
      //         link: '#'
      //       },
      //       {
      //         name: 'Github',
      //         class: 'go-to github',
      //         link: '#'
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Booka',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: '#fEDEE8',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'go-to web',
      //         link: '#'
      //       },
      //       // {
      //       //   name: 'Github',
      //       //   class: 'go-to github',
      //       //   link: '#'
      //       // },
      //     ],
      //   },
      //   {
      //     name: 'Breathe',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: '#fEDEE8',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'go-to web',
      //         link: '#'
      //       },
      //       // {
      //       //   name: 'Github',
      //       //   class: 'go-to github',
      //       //   link: '#'
      //       // },
      //     ],
      //   },
      //   {
      //     name: 'iOS picker Sketch template',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: '#fEDEE8',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'go-to web',
      //         link: '#'
      //       },
      //       // {
      //       //   name: 'Github',
      //       //   class: 'go-to github',
      //       //   link: '#'
      //       // },
      //     ],
      //   },
      //   {
      //     name: 'Apple\/Google store handover (Sketch template)',
      //     description: 'UICS\'s description - Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat.',
      //     detailsLink: '#',
      //     bgColor: '#fEDEE8',
      //     fontColor: '#00000',
      //     cardImage: 'http://boldbad.com/my_projects/iq/android/phone/login/screens/Login.1-Splash@2x.jpg',
      //     previewImage: 'http://boldbad.com/my_projects/iq/android/phone/task_listing/screens/Task_listing-Landing@2x.jpg',
      //     actions: [
      //       {
      //         name: 'Download',
      //         class: 'go-to web',
      //         link: '#'
      //       },
      //       // {
      //       //   name: 'Github',
      //       //   class: 'go-to github',
      //       //   link: '#'
      //       // },
      //     ],
      //   },
      // ],
    };
  },
  
  computed: {  
  },

  watch: {
  },

  mounted() {
    
    // moltenLeading("h1", {
    //   minline: 1.025,
    //   maxline: 1.21,
    //   minwidth: 400,
    //   maxwidth: 800
    // });
    // moltenLeading("h1", {
    //   minline: 1.025,
    //   maxline: 1.21,
    //   minwidth: 400,
    //   maxwidth: 800
    // });
  },
  
  methods: {

  },
}