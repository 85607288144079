import ComplexProjectViewer from '../../components/complex-project-viewer/complex-project-viewer.vue';
export default {
	components: {
		'complexProjectViewer': ComplexProjectViewer,
	},
    props: [
    'incognitoMode',
  ],

  data () {
    return {
        name: 'MI Research',
      incognitoName: 'Medical research managment',
      bgColor: '#17afd4',
      platforms: [
        {
          deviceName: 'web',
          tabName: 'Screens',
          screens: [
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-1@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-2@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-3@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-4@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-5@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-6@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-7@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-8@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-9@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-10@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-11@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-12@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-13@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-14@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-15@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-16@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-17@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-18@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-19@2x.jpg'},
            // { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-20@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-21@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-22@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-23@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-24@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-25@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-26@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-27@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-28@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-29@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-30@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-31@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-32@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-33@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-34@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-35@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-36@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-37@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-38@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-39@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-40@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-41@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-42@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-43@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-44@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-45@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-46@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-47@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-48@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-49@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-50@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-51@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-52@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-53@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-54@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-55@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-56@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-57@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-58@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-59@2x.jpg'},
            { image: 'https://palmaka.design/folio-db/projects/miresearch/web/img-60@2x.jpg'},
          ],
        },
      ],
    };
  },
}