export default {
  props: [
    'markdownInput',
  ],
  data () {
    return {
      input: '# hello'
    };
  },
  computed: {
    compiledMarkdown: function () {
      // return marked(this.input, { sanitize: true })
      return marked(this.markdownInput, { sanitize: true })
    }
  },
  mounted() {
  },  
  methods: {
  }
}