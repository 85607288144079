import Flickity from 'vue-flickity';
import flickityAsNavFor from 'flickity-as-nav-for';

import KenBurnsSlideshow from '../ken-burns-slideshow/ken-burns-slideshow.vue';
import WebBrowserBar from '../web-browser-bar/web-browser-bar.vue';
import Markdown from '../markdown-parser/markdown-parser.vue';

export default {
	components: {
		Flickity,
    'kenBurnsSlideshow': KenBurnsSlideshow,
    'webBrowserBar': WebBrowserBar,
    'markdown': Markdown,
	},
	props: [
		'name',
    'incognitoName',
    'incognitoMode',
    'platforms',
    'bgColor',
	],

  data () {
    return {
      selectedPlatform: 0,
      selectedScreen: 0,

      // ______________________
      // Set Flickity carousels

      projectScreens: {
        asNavFor: '.image-slides',
        initialIndex: 0,
        draggable: true,
        prevNextButtons: true, 
        pageDots: false,
        fade: false,
        adaptiveHeight: false,
        autoPlay: true,
        lazyLoad: true,
        // imagesLoaded: true,
        arrowShape: { 
          x0: 25,
          x1: 55, y1: 30,
          x2: 60, y2: 25,
          x3: 35
        },
      },
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    // moltenLeading("h1", {
    //   minline: 1.025,
    //   maxline: 1.21,
    //   minwidth: 400,
    //   maxwidth: 800
    // });
  },
  methods: {
    // nextInfoSlide() {
    //   this.$refs.screens.next();
    // },
    
  },
}