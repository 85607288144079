import AppNav from './components/nav-main/nav-main.vue';

export default {
  name: 'app',
  components: {
    'appNav': AppNav,
  },
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
    
  },
  methods: {
  },
}