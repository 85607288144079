export const mainDataStore = {

    // baseValues: [{
    //     bodyFontSize: 18,
    //     bodyLineHeight: 1.4,
    //     scalingRatio: 1.618,
    //     defaultGutter: 2,
    //     defaultPadding: 3,
    //     containerWidth: 1000,
    // }],

    // view modes

    viewFocus: 'preview',
    showBasicValuesPreview: false,
    showVerticalRhythmPanel: true,
    showTypesettingPanel: false,
    showCodeVariation: 'css',

    showValuesAs: 'px',
    resetCSS: true,
    applyCssGrid: true,

    responsiveModularScale: 'sameForAll',

    siteFonts: [{
      fontSource: 3,
      fontName: 'default font',
    }],

    // Default / mobile sizes 

    bodyFontSize: 16,
    bodyLineHeight: 1.4,
    bodyLineHeightList: 2.2,
    bodyFontWeight: 'initial',
    bodyFontStyle: "normal",
    bodyTextTransform: "initial",
    bodyLetterSpacing: 0,

    scalingRatio: 1.618,

    
    defaultGutter: 8,
    defaultPadding: 16,
    
    containerWidth: 1000,

    bodyMarginBottom: 1,

    enableFontSmoothing: true,

    displayFontFamily: 'inherit',
    displayFontSize: 120,
    displayLineHeight: 1.1,
    displayMarginBottom: .5,
    displayFontWeight: 600,
    displayFontStyle: "normal",
    displayTextTransform: "initial",
    displayLetterSpacing: "0",

    h1FontFamily: 'inherit',
    h1FontSize: 64,
    h1LineHeight: 1.1,
    h1MarginBottom: .5,
    h1FontWeight: 600,
    h1FontStyle: "normal",
    h1TextTransform: "initial",
    h1LetterSpacing: "0",

    h2FontFamily: 'inherit',
    h2FontSize: 39,
    h2LineHeight: 1.25,
    h2MarginBottom: .5,
    h2FontWeight: 600,
    h2FontStyle: "normal",
    h2TextTransform: "initial",
    h2LetterSpacing: "0",
    
    h3FontFamily: 'inherit',
    h3FontSize: 24,
    h3LineHeight: 1.5,
    h3MarginBottom: 1,
    h3FontWeight: 600,
    h3FontStyle: "normal",
    h3TextTransform: "initial",
    h3LetterSpacing: "0",
    
    h4FontFamily: 'inherit',
    h4FontSize: 22,
    h4LineHeight: 1.5,
    h4MarginBottom: 1,
    h4FontWeight: 600,
    h4FontStyle: "normal",
    h4TextTransform: "initial",
    h4LetterSpacing: "0",
    
    h5FontFamily: 'inherit',
    h5FontSize: 20,
    h5LineHeight: 1.5,
    h5MarginBottom: 1,
    h5FontWeight: 600,
    h5FontStyle: "normal",
    h5TextTransform: "initial",
    h5LetterSpacing: "0",
    
    bodyLgFontFamily: 'inherit',
    bodyLgFontSize: '19px',
    bodyLgLineHeight: 1.5,
    bodyLgMarginBottom: 1,
    bodyLgFontWeight: 400,
    bodyLgFontStyle: "normal",
    bodyLgTextTransform: "initial",
    bodyLgLetterSpacing: "0",
    
    captionFontSize: '13px',
    captionLineHeight: 1.5,
    captionMarginBottom: 1,
    captionFontWeight: 400,
    captionFontStyle: "normal",
    captionTextTransform: "initial",
    captionLetterSpacing: "0",
    
    labelFontFamily: 'inherit',
    labelFontSize: '11px',
    labelLineHeight: 1.5,
    labelMarginBottom: 1,
    labelFontWeight: 400,
    labelFontStyle: "normal",
    labelTextTransform: "initial",
    labelLetterSpacing: "initial",
    
    microFontFamily: 'inherit',
    microFontSize: '9px',
    microLineHeight: 1.5,
    microMarginBottom: 1,
    microFontWeight: 400,
    microFontStyle: "normal",
    microTextTransform: "initial",
    microLetterSpacing: "0",

    // ____________
    // Tablet Sizes

    tabletDefaultGutter: 7.5,
    tabletDefaultPadding: 15,

    tabletBodyFontSize: 15,
    tabletBodyLineHeight: 1.4,
    tabletBodyLineHeightList: 2.2,
    tabletBodyFontWeight: 'initial',
    tabletBodyFontStyle: "normal",
    tabletBodyTextTransform: "initial",
    tabletBodyLetterSpacing: 0,

    tabletScalingRatio: 1.5,

    tabletDefaultGutterSelector: 2,
    tabletDefaultPaddingSelector: 3,
    tabletContainerWidth: 1000,

    tabletBodyMarginBottom: 1,

    tabletDisplayFontFamily: 'inherit',
    tabletDisplayFontSize: 120,
    tabletDisplayLineHeight: 1.1,
    tabletDisplayMarginBottom: .5,
    tabletDisplayFontWeight: 600,
    tabletDisplayFontStyle: "normal",
    tabletDisplayTextTransform: "initial",
    tabletDisplayLetterSpacing: "0",

    tabletH1FontFamily: 'inherit',
    tabletH1FontSize: 64,
    tabletH1LineHeight: 1.1,
    tabletH1MarginBottom: .5,
    tabletH1FontWeight: 600,
    tabletH1FontStyle: "normal",
    tabletH1TextTransform: "initial",
    tabletH1LetterSpacing: "0",

    tabletH2FontFamily: 'inherit',
    tabletH2FontSize: 39,
    tabletH2LineHeight: 1.25,
    tabletH2MarginBottom: .5,
    tabletH2FontWeight: 600,
    tabletH2FontStyle: "normal",
    tabletH2TextTransform: "initial",
    tabletH2LetterSpacing: "0",
    
    tabletH3FontFamily: 'inherit',
    tabletH3FontSize: 24,
    tabletH3LineHeight: 1.5,
    tabletH3MarginBottom: 1,
    tabletH3FontWeight: 600,
    tabletH3FontStyle: "normal",
    tabletH3TextTransform: "initial",
    tabletH3LetterSpacing: "0",
    
    tabletH4FontFamily: 'inherit',
    tabletH4FontSize: 22,
    tabletH4LineHeight: 1.5,
    tabletH4MarginBottom: 1,
    tabletH4FontWeight: 600,
    tabletH4FontStyle: "normal",
    tabletH4TextTransform: "initial",
    tabletH4LetterSpacing: "0",
    
    tabletH5FontFamily: 'inherit',
    tabletH5FontSize: 20,
    tabletH5LineHeight: 1.5,
    tabletH5MarginBottom: 1,
    tabletH5FontWeight: 600,
    tabletH5FontStyle: "normal",
    tabletH5TextTransform: "initial",
    tabletH5LetterSpacing: "0",
    
    tabletBodyLgFontFamily: 'inherit',
    tabletBodyLgFontSize: '19px',
    tabletBodyLgLineHeight: 1.5,
    tabletBodyLgMarginBottom: 1,
    tabletBodyLgFontWeight: 400,
    tabletBodyLgFontStyle: "normal",
    tabletBodyLgTextTransform: "initial",
    tabletBodyLgLetterSpacing: "0",
    
    tabletCaptionFontSize: '13px',
    tabletCaptionLineHeight: 1.5,
    tabletCaptionMarginBottom: 1,
    tabletCaptionFontWeight: 400,
    tabletCaptionFontStyle: "normal",
    tabletCaptionTextTransform: "initial",
    tabletCaptionLetterSpacing: "0",
    
    tabletLabelFontFamily: 'inherit',
    tabletLabelFontSize: '11px',
    tabletLabelLineHeight: 1.5,
    tabletLabelMarginBottom: 1,
    tabletLabelFontWeight: 400,
    tabletLabelFontStyle: "normal",
    tabletLabelTextTransform: "initial",
    tabletLabelLetterSpacing: "initial",
    
    tabletMicroFontFamily: 'inherit',
    tabletMicroFontSize: '9px',
    tabletMicroLineHeight: 1.5,
    tabletMicroMarginBottom: 1,
    tabletMicroFontWeight: 400,
    tabletMicroFontStyle: "normal",
    tabletMicroTextTransform: "initial",
    tabletMicroLetterSpacing: "0",

    // ____________
    // Tablet Sizes

    desktopDefaultGutter: 8,
    desktopDefaultPadding: 16,

    desktopBodyFontSize: 16,
    desktopBodyLineHeight: 1.4,
    desktopBodyLineHeightList: 2.2,
    desktopBodyFontWeight: 'initial',
    desktopBodyFontStyle: "normal",
    desktopBodyTextTransform: "initial",
    desktopBodyLetterSpacing: 0,

    desktopScalingRatio: 1.618,

    desktopDefaultGutterSelector: 2,
    desktopDefaultPaddingSelector: 3,
    desktopContainerWidth: 1000,

    desktopBodyMarginBottom: 1,

    desktopDisplayFontFamily: 'inherit',
    desktopDisplayFontSize: 120,
    desktopDisplayLineHeight: 1.1,
    desktopDisplayMarginBottom: .5,
    desktopDisplayFontWeight: 600,
    desktopDisplayFontStyle: "normal",
    desktopDisplayTextTransform: "initial",
    desktopDisplayLetterSpacing: "0",

    desktopH1FontFamily: 'inherit',
    desktopH1FontSize: 64,
    desktopH1LineHeight: 1.1,
    desktopH1MarginBottom: .5,
    desktopH1FontWeight: 600,
    desktopH1FontStyle: "normal",
    desktopH1TextTransform: "initial",
    desktopH1LetterSpacing: "0",

    desktopH2FontFamily: 'inherit',
    desktopH2FontSize: 39,
    desktopH2LineHeight: 1.25,
    desktopH2MarginBottom: .5,
    desktopH2FontWeight: 600,
    desktopH2FontStyle: "normal",
    desktopH2TextTransform: "initial",
    desktopH2LetterSpacing: "0",
    
    desktopH3FontFamily: 'inherit',
    desktopH3FontSize: 24,
    desktopH3LineHeight: 1.5,
    desktopH3MarginBottom: 1,
    desktopH3FontWeight: 600,
    desktopH3FontStyle: "normal",
    desktopH3TextTransform: "initial",
    desktopH3LetterSpacing: "0",
    
    desktopH4FontFamily: 'inherit',
    desktopH4FontSize: 22,
    desktopH4LineHeight: 1.5,
    desktopH4MarginBottom: 1,
    desktopH4FontWeight: 600,
    desktopH4FontStyle: "normal",
    desktopH4TextTransform: "initial",
    desktopH4LetterSpacing: "0",
    
    desktopH5FontFamily: 'inherit',
    desktopH5FontSize: 20,
    desktopH5LineHeight: 1.5,
    desktopH5MarginBottom: 1,
    desktopH5FontWeight: 600,
    desktopH5FontStyle: "normal",
    desktopH5TextTransform: "initial",
    desktopH5LetterSpacing: "0",
    
    desktopBodyLgFontFamily: 'inherit',
    desktopBodyLgFontSize: '19px',
    desktopBodyLgLineHeight: 1.5,
    desktopBodyLgMarginBottom: 1,
    desktopBodyLgFontWeight: 400,
    desktopBodyLgFontStyle: "normal",
    desktopBodyLgTextTransform: "initial",
    desktopBodyLgLetterSpacing: "0",
    
    desktopCaptionFontSize: '13px',
    desktopCaptionLineHeight: 1.5,
    desktopCaptionMarginBottom: 1,
    desktopCaptionFontWeight: 400,
    desktopCaptionFontStyle: "normal",
    desktopCaptionTextTransform: "initial",
    desktopCaptionLetterSpacing: "0",
    
    desktopLabelFontFamily: 'inherit',
    desktopLabelFontSize: '11px',
    desktopLabelLineHeight: 1.5,
    desktopLabelMarginBottom: 1,
    desktopLabelFontWeight: 400,
    desktopLabelFontStyle: "normal",
    desktopLabelTextTransform: "initial",
    desktopLabelLetterSpacing: "initial",
    
    desktopMicroFontFamily: 'inherit',
    desktopMicroFontSize: '9px',
    desktopMicroLineHeight: 1.5,
    desktopMicroMarginBottom: 1,
    desktopMicroFontWeight: 400,
    desktopMicroFontStyle: "normal",
    desktopMicroTextTransform: "initial",
    desktopMicroLetterSpacing: "0",
    
    // ___________
    // breakpoints

    breakpointPhoneLandscape: 415,
    breakpointTabletPortrait: 740,
    breakpointTabletLandscape: 1020,
    breakpointLaptop: 1200,
    breakpointDesktop: 1440,
    breakpointDesktopLg: 1400,

    pageGridColumnsPhone: '1fr 1fr 1fr',
    enableGridRowsPhone: false,

    pageGridColumnsPhoneLandscape: '1fr 1fr 1fr 1fr',
    pageGridColumnsTabletPortrait: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    pageGridColumnsTabletLandscape: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    pageGridColumnsLaptop: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    pageGridColumnsDesktop: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    navbarGridColumnsDesktopMode: 'none',
    navbarGridColumnsDesktop: '1fr 2fr 2fr 1fr',

    platforms: {
      web: true,
      ios: false,
      android: false,
    },

    // spacingFormulas: {
    //   span0: 0,
    //   span1: 1,
    //   span2: this.bodyFontSize/2,
    //   span3: this.bodyFontSize,
    //   span4: this.bodyFontSize * this.bodyLineHeight,
    //   span5: (this.bodyFontSize * this.bodyLineHeight) * this.scalingRatio,
    //   span6: (this.bodyFontSize * this.bodyLineHeight) * this.scalingRatio * this.bodyLineHeight,
    //   span7: (this.bodyFontSize * this.bodyLineHeight) * (2 * this.scalingRatio),
    //   span8: (this.bodyFontSize * this.bodyLineHeight) * (2 * this.scalingRatio) * this.bodyLineHeight,
    // },
}